import React, { useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import {createContact} from './contactSlice';

import './ContactForm.css';

const { Option } = Select;

interface ContactFormProps {
    onFinish: () => void;
  }
  

const ContactForm: React.FC<ContactFormProps> = ({ onFinish }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    state: '',
    zip: '',
    email: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (closeModal: () => void) => {
    await createContact(formData);
    setFormData({
      firstName: '',
      lastName: '',
      state: '',
      zip: '',
      email: ''
    }); // Reset form data to initial state
    onFinish(); // Close the modal
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item label="First Name" required>
        <Input
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item label="Last Name" required>
        <Input
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item label="State" required>
        <Select
          value={formData.state}
          onChange={value => setFormData(prev => ({ ...prev, state: value }))}
        >
          <Option value="NY">New York</Option>
          <Option value="CA">California</Option>
          <Option value="TX">Texas</Option>
        </Select>
      </Form.Item>
      <Form.Item label="ZIP Code" required>
        <Input
          name="zip"
          value={formData.zip}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item label="Email" required>
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
