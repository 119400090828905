import { doc, setDoc } from 'firebase/firestore';
import db, { auth } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';

export interface ContactFormData {
  firstName: string;
  lastName: string;
  state: string;
  zip: string;
  email: string;
}

export async function createContact(contactForm: ContactFormData) {
    const path = `leads`;
    const docRef = doc(db, path, uuidv4());

    await setDoc(docRef, {
      ...contactForm
    });
  }