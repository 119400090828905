import React, { useState } from 'react';
import { Input, Button, Card, Tag, Modal, Spin } from 'antd';
import { UserOutlined, RobotOutlined } from '@ant-design/icons';

import ContactForm from '../ContactForm';

import './Chat.css';

const { CohereClient } = require('cohere-ai');

const Chat = () => {
    const [messages, setMessages] = useState<{ message: string; role: string, citationSources?: [], formattedMessage?: string }[]>([]);
    const [citationSources, setCitationSources] = useState<string[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [contactModelVisible, setContactModelVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const handleSendClick = () => {
        if (inputValue.trim()) {
            setMessages(prevMessages => {
                const updatedMessages = [...prevMessages, { message: inputValue, role: 'USER' }];
                sendMessageToServer(inputValue, updatedMessages);
                return updatedMessages;
            });
            setInputValue('');
        }
    };
    const handleAboutClick = () => {
        setModalVisible(true);
    };

    const handleAgentClick = () => {
        console.log('to come')
        setContactModelVisible(true);
    };

    interface Citation {
        start: number;
        end: number;
        text: string;
        document_ids: string[];
    }

    interface CitationMessage {
        eventType: string;
        is_finished: boolean;
        citations: Citation[];
    }

    const highlightBotResponse = (response: string, citations: CitationMessage[]) => {
        let highlightedResponse = response;

        for (const msg of citations) {
            if (msg.eventType === 'citation-generation') {
                for (const citation of msg.citations) {
                    const text = citation.text;
                    const start = highlightedResponse.indexOf(text);
                    const end = start + text.length;
                    highlightedResponse = highlightedResponse.substring(0, start) + `<span style="background-color: #5f8baa; color: #ffffff; padding: 2px">${highlightedResponse.substring(start, end)}</span>` + highlightedResponse.substring(end);
                }
            }
        }

        return highlightedResponse;
    };

    const sendMessageToServer = async (message: string, currentMessages: { message: string; role: string }[]) => {
        setLoading(true);
        const chatHistory = currentMessages.map(msg => ({ role: msg.role, message: msg.message }));
        // Use Cohere's client-side API to generate chat responses
        const cohere = new CohereClient({
            token: process.env.REACT_APP_COHERE_API_TOKEN,
          });
        const chatStream = await cohere.chatStream({
            chatHistory: chatHistory,
            message: message,
            connectors: [{
                id: "web-search",
                options: {
                    site: "https://www.medicare.gov/"
                }
            }]
        });


        let fullResponse = '';
        let data = [];
        for await (const msg of chatStream) {
            if (msg.eventType === 'text-generation') {
                fullResponse += msg.text;
            }
            data.push(msg);
        }

        const reply = fullResponse;
        const citations = data.filter((msg: any) => msg.eventType === 'citation-generation');
        const citationSource = data.filter((msg: any) => msg.eventType === 'search-results');
        const citationSourceDocs = citationSource[0].documents;
        setCitationSources(citationSourceDocs);
        const highlightedReply = highlightBotResponse(reply, citations);
        setMessages([...currentMessages, { message: reply, role: 'CHATBOT', citationSources: citationSourceDocs, formattedMessage: highlightedReply }]);
        setLoading(false);
    };

    return (
        <>
            <Modal
                title="About"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                Best Medicare Information is the trusted source of Medicare information. We provide the most accurate and up-to-date information about Medicare. Our chatbot is powered by the latest AI technology to provide you with the best answers to your questions. Data comes directly from Medicare.gov.
            </Modal>
            <Modal
                title="Contact an Agent"
                open={contactModelVisible}
                onCancel={() => setContactModelVisible(false)}
                footer={null}
            >
            <ContactForm onFinish={() => setContactModelVisible(false)} />
            </Modal>
            <div className="chat-container">
                <div className="chat-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ flex: 1, textAlign: 'center' }}>Best Medicare Information</div>
                    <Button type="link" onClick={handleAboutClick} style={{ alignSelf: 'flex-end' }}>
                        About
                    </Button>
                    <Button type="link" onClick={handleAgentClick} style={{ alignSelf: 'flex-end' }}>
                        Talk to An Agent
                    </Button>
                </div>
                <div className="messages">
                    {messages.map((msg, index) => (
                        <Card key={index} className="message-card">
                            <div style={{ display: 'flex', width: '100%', columnGap: '0.5rem' }}>
                                <div style={{ height: '25px', width: '25px', borderRadius: '2px', backgroundColor: msg.role === 'USER' ? '#5f8baa' : '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {msg.role === 'USER' ? <UserOutlined style={{ color: 'white', fontSize: '16px' }} /> : <RobotOutlined style={{ color: '#6ea139', fontSize: '24px' }} />}
                                </div>
                                {loading && msg.role !== 'USER' && msg.message === undefined ? (
                                    <div> Generating Response <Spin /> </div>
                                ) : (
                                    <div dangerouslySetInnerHTML={{ __html: msg.formattedMessage || msg.message || '' }} />
                                )}
                            </div>
                            {msg.role !== 'USER' && msg.citationSources && msg.citationSources.length > 0 ? (
                                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                    Citations:
                                    {msg.citationSources.map((source: any, index) => (
                                        <div key={index} style={{ margin: '4px' }}>
                                            <Tag color='blue'>
                                                <a href={source.url} target="_blank" rel="noopener noreferrer">{source.title}</a>
                                            </Tag>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </Card>
                    ))}
                {loading && (
                    <Card className="message-card">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                            Generating Response <Spin style={{paddingLeft: '2px'}}/>
                        </div>
                    </Card>
                )}
                </div>
                <Input.Group compact style={{ display: 'flex' }}>
                    <Input
                        style={{ flex: 1 }}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onPressEnter={handleSendClick}
                        placeholder='Ask a question about Medicare...'
                    />
                    <Button type="primary" onClick={handleSendClick}>
                        Send
                    </Button>
                </Input.Group>
                <div style={{ color: 'lightgray', marginTop: '5px', marginBottom: '5px'}}>
                    Data from <a href="https://www.medicare.gov" style={{ color: 'lightblue' }} target="_blank" rel="noopener noreferrer">Medicare.gov</a>
                </div>
            </div>
        </>
    );
};

export default Chat;